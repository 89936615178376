import { version } from './version';

export const environment = {
  BASE_URL: 'https://deccontagem.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3118601',
  schema: 'pbi_dte_contagem',
  groupId: '63954077-12bc-4b75-b0da-80ce5fa8833e',
  analiseGeral: 'ca905d69-e8f6-42fe-9d48-432127f5ae12',
  analiseUsuarios: 'd5623b56-e21e-466f-bea1-5cbd1e8e9f11',
  analiseComunicados: '5a877258-24b9-417f-8d02-99c7957fd2bf',
  analiseProcurador: 'cfed6f30-953c-4d37-b365-1427c3bec0aa',
  analiseEventos: '2c1cd79c-86de-4003-aad7-307d68549a83',
  analiseContribuintes: '656e2354-a12e-4279-985a-7968dfeba47c'
};
